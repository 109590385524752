import { Box, Card, Container, Stack, Typography } from "@mui/material";
import Header from "./Header";
import Main from "./Main";
import { Outlet } from "react-router";

// ----------------------------------------------------------------------

export default function StepsLayout() {
  return (
    <>
      <Header />

      <Main>
        <Outlet />
      </Main>
      <Box
        component="footer"
        sx={{
          position: "fixed",
          width: "100%",
          bottom: 0,
          backgroundColor: "#375168",
        }}
      >
        {/* <Divider /> */}
        <Container>
          <Stack
            flexDirection={{ xs: "column", sm: "row" }}
            sx={{ color: "white", pt: 1, pb: 1}}
            justifyContent={"space-between"}
          >
              <Card sx={{display:"flex",p:1}} >
                <Typography variant="subtitle2">
                  Helpline Numbers : {process.env.REACT_APP_COMPANY_MOBILE} ,
                  {""}
                  {process.env.REACT_APP_COMPANY_OTHE_MOBILE}
                </Typography>
                <Typography variant="subtitle2">
                  Wallet Update : {process.env.REACT_APP_COMPANY_MOBILE1} ,{""}
                  {process.env.REACT_APP_COMPANY_MOBILE2}
                </Typography>
                </Card>
                <Card sx={{display:"flex",p:1}}>
                <Typography variant="subtitle2">
                  Timing - 09:00 AM - 09:00 PM ( 7 Days )
                </Typography>
                <Typography variant="subtitle2">
                Helpline Email : {process.env.REACT_APP_COMPANY_EMAIL}
                </Typography>
              </Card>
            </Stack>

        </Container>
      </Box>
    </>
  );
}
