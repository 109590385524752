// @mui
import { useTheme } from "@mui/material/styles";
import {
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Divider,
  Tooltip,
  Typography,
} from "@mui/material";
// utils
import { bgBlur } from "../../../utils/cssStyles";
// hooks
import useOffSetTop from "../../../hooks/useOffSetTop";
import useResponsive from "../../../hooks/useResponsive";
// config
import { HEADER, NAV } from "../../../config";
// components
import Logo from "../../../components/logo";
import Iconify from "../../../components/iconify";
import { useSettingsContext } from "../../../components/settings";
//
import Searchbar from "./Searchbar";
import AccountPopover from "./AccountPopover";
import LanguagePopover from "./LanguagePopover";
import ContactsPopover from "./ContactsPopover";
import NotificationsPopover from "./NotificationsPopover";
import Label from "src/components/label/Label";
import { useAuthContext } from "src/auth/useAuthContext";
import { fIndianCurrency } from "src/utils/formatNumber";
import { useState } from "react";

// ----------------------------------------------------------------------

type Props = {
  onOpenNav?: VoidFunction;
};

export default function Header({ onOpenNav }: Props) {
  const theme = useTheme();

  const { user, initialize, logout } = useAuthContext();
  const [loading, setLoading] = useState(false);

  const { themeLayout } = useSettingsContext();

  const isNavHorizontal = themeLayout === "horizontal";

  const isNavMini = themeLayout === "mini";

  const isDesktop = useResponsive("up", "lg");

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;

  const walletStyle = {
    textTransform: "capitalize",
    borderColor: "primary",
    borderRadius: 1,
    borderWidth: "1px",
    borderStyle: "solid",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    height: "fit-content",
  };

  const renderContent = (
    <>
      {/* {isDesktop && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />} */}
      {<Logo sx={{ mr: 2.5 }} />}

      {/* {!isDesktop && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1, color: "text.primary" }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      )} */}

      <Searchbar />

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1.5 }}
      >
        <Label variant="soft" sx={walletStyle}>
          {!loading ? (
            <Typography variant="body2">
              wallet = {fIndianCurrency(user?.main_wallet_amount) || 0}
            </Typography>
          ) : (
            <Iconify icon="eos-icons:bubble-loading" width="30px" />
          )}
        </Label>

        <Tooltip title="Refresh wallet">
          <IconButton
            disabled={loading}
            onClick={() => {
              if (!loading) {
                setLoading(true);
                setTimeout(async () => {
                  await initialize();
                  setLoading(false);
                }, 1000);
              }
            }}
            sx={{ mr: 1, color: "text.primary" }}
          >
            <Iconify icon="mage:refresh-reverse" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Logout">
          <Stack onClick={logout}>
            <IconButton>
              <Iconify icon="mdi:exit-run" sx={{ width: 25, height: 25 }} />
            </IconButton>
          </Stack>
        </Tooltip>
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: "none",
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        borderBottom: (theme) => `solid 2px ${theme.palette.divider}`,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(["height"], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
          height: HEADER.H_DASHBOARD_DESKTOP,
          borderBottom: (theme) => `solid 2px ${theme.palette.divider}`,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: (theme) => `solid 2px ${theme.palette.divider}`,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: "background.default",
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
            borderBottom: (theme) => `solid 2px  ${theme.palette.divider}`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
          bgcolor: theme.palette.primary.lighter,
        }}
        variant="dense"
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
