import React, { useEffect, useState } from "react";
import * as Yup from "yup";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Link,
  Stack,
  Alert,
  IconButton,
  InputAdornment,
  useTheme,
  Typography,
  FormHelperText,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// auth
import { useAuthContext } from "../../auth/useAuthContext";
// components
import Iconify from "../../components/iconify";
import FormProvider, {
  RHFCodes,
  RHFSecureCodes,
  RHFTextField,
} from "../../components/hook-form";

import { useSnackbar } from "notistack";
import { useNavigate } from "react-router";
import { fetchLocation } from "src/utils/fetchLocation";
import Carosal from "src/layouts/Carosal";
import MotionModal from "src/components/animate/MotionModal";
import useLocalStorage from "src/hooks/useLocalStorage";

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string;
  password: string;
  afterSubmit?: string;
};
type FormValuesPropsotp = {
  otp1: string;
  otp2: string;
  otp3: string;
  otp4: string;
  otp5: string;
  otp6: string;
  payAmount: string;
};

export default function AuthLoginForm() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { login, Api } = useAuthContext();
  const [loginData, setLoginData] = useState<any>([]);
  const [s, sets] = useState<any>("");
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    otpReset();
  };
  const defaultValues2 = {
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
  };

  const OtpSchema = Yup.object().shape({
    otp1: Yup.string().required("Code is required"),
    otp2: Yup.string().required("Code is required"),
    otp3: Yup.string().required("Code is required"),
    otp4: Yup.string().required("Code is required"),
    otp5: Yup.string().required("Code is required"),
    otp6: Yup.string().required("Code is required"),
  });

  const method2 = useForm<FormValuesPropsotp>({
    resolver: yupResolver(OtpSchema),
    defaultValues: defaultValues2,
  });

  const {
    reset: otpReset,
    watch: watchOpt,
    setValue: otpSetValue,
    handleSubmit: handleOtpSubmit,
    formState: { errors: error2, isSubmitting: isSubmitting2 },
  } = method2;

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email or mobile number is required")
      .test(
        "valid-email-or-mobile",
        "Enter a valid email address or mobile number",
        function (value) {
          const isEmail = Yup.string().email().isValidSync(value);
          const isMobile = Yup.string()
            .matches(/^[0-9]{10}$/, "Invalid mobile number")
            .isValidSync(value);

          return isEmail || isMobile;
        }
      ),
    password: Yup.string().required("Password is required"),
  });

  const defaultValues = {
    email: "",
    password: "",
  };

  useEffect(() => {
    function makeid(length: number) {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }
      return result;
    }
    if (!localStorage.getItem("uniqueLoginKey")) {
      localStorage.setItem("uniqueLoginKey", makeid(10));
    }

    fetchLocation();
  }, []);

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    let body = {
      username: data.email.toLocaleLowerCase(),
      password: data.password,
    };
    try {
      await Api(`auth/login_in`, "POST", body, "").then(
        async (Response: any) => {
          if (Response.status == 200) {
            if (Response.data.code == 200) {
              await Api(
                `auth/userData`,
                "GET",
                "",
                Response.data.data.token
              ).then(async (response: any) => {
                if ((response.status = 200)) {
                  if (response.data.code == 200) {
                    if (
                      response.data.data !== null &&
                      response.data.data.userData.role == "agent"
                    ) {
                      await Api(
                        "agent/getActiveTransferServiceList",
                        "GET",
                        "",
                        Response.data.data.token
                      ).then((serviceResponse: any) => {
                        if (serviceResponse.status == 200) {
                          if (serviceResponse.data.code == 200) {
                            const {
                              upiTransaferServiceStatus,
                              moneyTransaferServiceStatus,
                              vendorPaymentServiceStatus,
                              masterTransferSwitch,
                            } = serviceResponse.data.masterTransferData;
                            const {
                              userMoneyTransaferServiceStatus,
                              userUpiTransaferServiceStatus,
                              userVendorPaymentServiceStatus,
                            } = serviceResponse.data.data;

                            if (
                              masterTransferSwitch &&
                              ((upiTransaferServiceStatus &&
                                serviceResponse.data.data
                                  .upiTransaferServiceStatus) ||
                                (moneyTransaferServiceStatus &&
                                  serviceResponse.data.data
                                    .moneyTransaferServiceStatus) ||
                                (vendorPaymentServiceStatus &&
                                  serviceResponse.data.data
                                    .vendorPaymentServiceStatus))
                            ) {
                              login(Response.data.data.token, {
                                ...response.data.data.userData,
                                transferServices: {
                                  userData: serviceResponse.data.data,
                                  masterData:
                                    serviceResponse.data.masterTransferData,
                                },
                                attendanceAEPS:
                                  response.data.data.userData.attendanceAEPS,
                                attendanceAP:
                                  response.data.data.userData.attendanceAP,
                                m_distCode:
                                  response.data.data.userData.m_distCode,
                              });
                              enqueueSnackbar(Response.data.message);
                            } else {
                              enqueueSnackbar(
                                "services currently down. please contact to Admin.",
                                {
                                  variant: "error",
                                }
                              );
                            }
                          } else {
                            enqueueSnackbar(serviceResponse.data.message, {
                              variant: "error",
                            });
                          }
                        } else {
                          enqueueSnackbar("Failed", {
                            variant: "error",
                          });
                        }
                      });
                    } else {
                      enqueueSnackbar("Invalid User", { variant: "error" });
                    }
                  }
                }
              });
            } else if ([452, 454, 455].includes(Response.data.code)) {
              enqueueSnackbar(Response.data.message);
              // setuserID(Response?.data?.user);
              setLoginData(Response?.data);
              setOpen(true);
            } else {
              enqueueSnackbar(Response.data.message, { variant: "error" });
            }
          } else {
            enqueueSnackbar("Server Error", { variant: "error" });
          }
        }
      );
    } catch (error) {
      if (error.code == 1) {
        enqueueSnackbar(`${error.message}. Please allow location !`);
      }
      reset();
      console.error(error);
    }
  };

  const formSubmit = async (data: FormValuesPropsotp) => {
    let body = {
      otp:
        data.otp1 + data.otp2 + data.otp3 + data.otp4 + data.otp5 + data.otp6,
      type:
        loginData?.code == 455
          ? "dailyLogin"
          : loginData?.code == 452 || loginData?.code == 454
          ? "deviceChange"
          : "",
      user: loginData?.user,
    };
    try {
      await Api(`auth/verifyOTP`, "POST", body, "").then(
        async (Response: any) => {
          if (Response.status == 200) {
            if (Response.data.code == 200) {
              await Api(
                `auth/userData`,
                "GET",
                "",
                Response.data.data.token
              ).then(async (response: any) => {
                if ((response.status = 200)) {
                  if (response.data.code == 200) {
                    if (
                      response.data.data !== null &&
                      response.data.data.userData.role == "agent"
                    ) {
                      await Api(
                        "agent/getActiveTransferServiceList",
                        "GET",
                        "",
                        Response.data.data.token
                      ).then((serviceResponse: any) => {
                        if (serviceResponse.status == 200) {
                          if (serviceResponse.data.code == 200) {
                            const {
                              upiTransaferServiceStatus,
                              moneyTransaferServiceStatus,
                              vendorPaymentServiceStatus,
                              masterTransferSwitch,
                            } = serviceResponse.data.masterTransferData;
                            const {
                              userMoneyTransaferServiceStatus,
                              userUpiTransaferServiceStatus,
                              userVendorPaymentServiceStatus,
                            } = serviceResponse.data.data;

                            if (
                              masterTransferSwitch &&
                              ((upiTransaferServiceStatus &&
                                serviceResponse.data.data
                                  .upiTransaferServiceStatus) ||
                                (moneyTransaferServiceStatus &&
                                  serviceResponse.data.data
                                    .moneyTransaferServiceStatus) ||
                                (vendorPaymentServiceStatus &&
                                  serviceResponse.data.data
                                    .vendorPaymentServiceStatus))
                            ) {
                              login(Response.data.data.token, {
                                ...response.data.data.userData,
                                transferServices: {
                                  userData: serviceResponse.data.data,
                                  masterData:
                                    serviceResponse.data.masterTransferData,
                                },
                                attendanceAEPS:
                                  response.data.data.userData.attendanceAEPS,
                                attendanceAP:
                                  response.data.data.userData.attendanceAP,
                                m_distCode:
                                  response.data.data.userData.m_distCode,
                              });
                              enqueueSnackbar(Response.data.message);
                            } else {
                              enqueueSnackbar(
                                "services currently down. please contact to Admin.",
                                {
                                  variant: "error",
                                }
                              );
                            }
                          } else {
                            enqueueSnackbar(serviceResponse.data.message, {
                              variant: "error",
                            });
                          }
                        } else {
                          enqueueSnackbar("Failed", {
                            variant: "error",
                          });
                        }
                      });
                    } else {
                      enqueueSnackbar("Invalid User", { variant: "error" });
                    }
                  }
                }
              });
            } else {
              enqueueSnackbar(Response.data.message, { variant: "error" });
            }
          } else {
            enqueueSnackbar("Server Error", { variant: "error" });
          }
        }
      );
    } catch (error) {
      if (error.code == 1) {
        enqueueSnackbar(`${error.message}. Please allow location !`);
      }
      reset();
      console.error(error);
    }
  };

  return (
    <>
      <React.Fragment>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <RHFTextField name="email" label="User Name / Mobile Number" />

            <RHFTextField
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <Stack alignItems="flex-end" sx={{ my: 2 }}>
            <Link
              variant="body2"
              color="inherit"
              underline="always"
              sx={{ cursor: "pointer" }}
              onClick={() => navigate("/reset")}
            >
              Forgot password?
            </Link>
          </Stack>

          <LoadingButton
            fullWidth
            size="medium"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Login
          </LoadingButton>
        </FormProvider>
      </React.Fragment>

      <MotionModal open={open} width={{ xs: "95%", sm: 500 }}>
        <Typography variant="h6" textAlign={"center"}>
          Please Enter OTP
        </Typography>

        <FormProvider methods={method2} onSubmit={handleOtpSubmit(formSubmit)}>
          <Stack
            alignItems={"center"}
            justifyContent={"space-between"}
            mt={2}
            gap={2}
          >
            <RHFCodes
              keyName="otp"
              inputs={["otp1", "otp2", "otp3", "otp4", "otp5", "otp6"]}
            />

            {(!!error2.otp1 ||
              !!error2.otp2 ||
              !!error2.otp3 ||
              !!error2.otp4 ||
              !!error2.otp5 ||
              !!error2.otp6) && (
              <FormHelperText error sx={{ px: 2 }}>
                Code is required
              </FormHelperText>
            )}
            <Stack flexDirection={"row"} gap={1} mt={2}>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={isSubmitting2}
                size="small"
              >
                Continue
              </LoadingButton>
              <Button
                variant="contained"
                size="small"
                disabled={isSubmitting2}
                onClick={handleClose}
              >
                Close
              </Button>
            </Stack>
          </Stack>
        </FormProvider>
      </MotionModal>
    </>
  );
}
