// @mui
import {
  Typography,
  Stack,
  Box,
  Container,
  useTheme,
  Card,
} from "@mui/material";
// components
import Logo from "../../components/logo";
import Image from "../../components/image";
import koneDashBoardImage from "../../assets/images/konedashboard.svg";
import nrupeeDashBoardImage from "../../assets/images/nrupeedashboard.svg";
import justrealpayBoardImage from "../../assets/images/payjustdashboard.svg";

//
import {
  StyledRoot,
  StyledSectionBg,
  StyledSection,
  StyledContent,
} from "./styles";
import Marquee from "react-fast-marquee";
import SvgColor from "src/components/svg-color";
import packageFile from "../../../package.json";
import Carosal from "../Carosal";
import Iconify from "src/components/iconify/Iconify";

// ----------------------------------------------------------------------

type Props = {
  title?: string;
  illustration?: string;
  children: React.ReactNode;
};

export default function Layout({ children, illustration, title }: Props) {
  const theme = useTheme();
  return (
    <StyledRoot>
      <Logo
        sx={{
          zIndex: 9,
          position: "absolute",
          mt: { xs: 1, md: 1 },
          ml: { xs: 1, md: 5 },
        }}
      />

      {/* <StyledSection>
        <Typography
          variant="h5"
          sx={{ mb: 5, maxWidth: 580, textAlign: "center" }}
        >
          Join {process.env.REACT_APP_COMPANY_NAME} to drive Financial Inclusion
          in the country!
        </Typography>
        <Stack
          sx={{
            width: "100%",
            borderRadius: 1,
            overflow: "clip",
            boxShadow: (theme) => theme.customShadows.z4,
            [theme.breakpoints.up("md")]: {
              width: 720,
            },
          }}
        >
        </Stack>
        <Typography
          variant="subtitle1"
          sx={{ mt: 5, mb: 14, maxWidth: 580, textAlign: "center" }}
        >
          Do not share login OTP or password with anyone. realpay employee will
          never ask for these details.
        </Typography>

        <StyledSectionBg />
      </StyledSection> */}

      <StyledContent>
        <Stack sx={{ width: "100%" }}> {children} </Stack>
      </StyledContent>

      <Box
        component="footer"
        sx={{
          position: "fixed",
          width: "100%",
          bottom: 0,
          // backgroundColor: "#375168",
        }}
      >
        <Stack flexDirection={"row"} justifyContent={"space-between"} px={1}>
          <Typography variant="caption">
            v.
            {process.env.REACT_APP_ENV == "DEV"
              ? packageFile.versiondev
              : packageFile.versionprod}
          </Typography>

          <Typography variant="caption">
            Made with{" "}
            <Iconify
              icon="fluent-emoji-flat:red-heart"
              width="48"
              height="48"
            />{" "}
            by ALPHA
          </Typography>

          <Typography variant="caption">
            Last Updated :- {packageFile.lastUpdated}
          </Typography>
        </Stack>
        {/* 
        <Stack
          sx={{ bgcolor: "white" }}
          flexDirection={"row"}
          justifyContent={"space-between"}
          px={1}
        >
          <Typography variant="caption">
            v.
            {process.env.REACT_APP_ENV == "DEV"
              ? packageFile.versiondev
              : packageFile.versionprod}
          </Typography>
          <Typography variant="caption">
            Last Updated :- {packageFile.lastUpdated}
          </Typography>
        </Stack>
        <Marquee style={{ background: "white" }} pauseOnHover>
          <SvgColor
            src={"/assets/icons/marqueeimages/recharge.svg"}
            sx={{ width: 120, height: 50, bgcolor: theme.palette.primary.main }}
          /> */}
        {/* <SvgColor
            src={"/assets/icons/marqueeimages/dmt.svg"}
            sx={{ width: 120, height: 70, bgcolor: theme.palette.primary.main }}
          /> */}
        {/* <SvgColor
            src={"/assets/icons/marqueeimages/dmt1.svg"}
            sx={{ width: 120, height: 70, bgcolor: theme.palette.primary.main }}
          /> */}
        {/* <SvgColor
            src={"/assets/icons/marqueeimages/dmt2.svg"}
            sx={{ width: 120, height: 70, bgcolor: theme.palette.primary.main }}
          /> */}
        {/* <SvgColor
            src={"/assets/icons/marqueeimages/billpayment.svg"}
            sx={{ width: 120, height: 70, bgcolor: theme.palette.primary.main }}
          /> */}
        {/* <SvgColor
            src={"/assets/icons/marqueeimages/indonepal.svg"}
            sx={{ width: 120, height: 70, bgcolor: theme.palette.primary.main }}
          /> */}
        {/* <SvgColor
            src={"/assets/icons/marqueeimages/matm.svg"}
            sx={{ width: 120, height: 70, bgcolor: theme.palette.primary.main }}
          /> */}
        {/* <SvgColor
            src={"/assets/icons/marqueeimages/aeps.svg"}
            sx={{ width: 120, height: 70, bgcolor: theme.palette.primary.main }}
          /> */}
        {/* <SvgColor
            src={"/assets/icons/marqueeimages/aadhaarpay.svg"}
            sx={{ width: 120, height: 70, bgcolor: theme.palette.primary.main }}
          /> */}
        {/* </Marquee>
        <Container>
          <Stack
            flexDirection={"row"}
            sx={{ color: "white", pt: 1, pb: 1 }}
            justifyContent={"space-between"}
          >
            <Card sx={{ display: "flex", flexDirection: "row", p: 1 }}>
              {" "}
              <Typography variant="subtitle2">
                Helpline Numbers : {process.env.REACT_APP_COMPANY_MOBILE} ,{""}
                {process.env.REACT_APP_COMPANY_OTHE_MOBILE}
              </Typography>
              <Typography variant="subtitle2">
                Wallet Update : {process.env.REACT_APP_COMPANY_MOBILE1} ,{""}
                {process.env.REACT_APP_COMPANY_MOBILE2}
              </Typography>
            </Card>
            <Card sx={{ display: "flex", flexDirection: "row", p: 1 }}>
              {" "}
              <Typography variant="subtitle2">
                Timing - 09:00 AM - 09:00 PM ( 7 Days )
              </Typography>
              <Typography variant="subtitle2">
                Helpline Email : {process.env.REACT_APP_COMPANY_EMAIL}
              </Typography>
            </Card>
          </Stack>
        </Container>*/}
      </Box>
    </StyledRoot>
  );
}
